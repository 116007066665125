<ion-menu contentId="main-content">
  <ion-content>
    <ion-list class="mt-1">
      <ion-item button detail="false" (click)="navigateToHome()">
        <ion-label>{{"Home.Title" | translate}}</ion-label>
      </ion-item>
      <ion-item button detail="false" (click)="navigateToParkingRules()">
        <ion-label>{{"ParkingRules.Title" | translate}}</ion-label>
      </ion-item>
      <ion-item button detail="false" (click)="navigateToTowingCompany()">
        <ion-label>{{"TowingCompanyInfo.Title" | translate}}</ion-label>
      </ion-item>
      <ion-item button detail="false" (click)="signout()"> 
        <ion-label color="danger">{{"SignOut" | translate}}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>
</ion-menu>