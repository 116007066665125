import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MenuComponent } from './menu/menu.component';
import { MaskitoDirective } from '@maskito/angular';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    MaskitoDirective
  ],
  providers: [DatePipe],
  declarations: [MenuComponent],
  exports: [MenuComponent, TranslateModule, MaskitoDirective]
})
export class SharedModule { }