import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  constructor(private router: Router, private menuController: MenuController, private apiService: ApiService) { }

  ngOnInit() { }

  navigateToHome() {
    this.menuController.close();
    this.router.navigateByUrl('home');
  }

  navigateToParkingRules() {
    this.menuController.close();
    this.router.navigateByUrl('parking-rules');
  }

  navigateToTowingCompany() {
    this.menuController.close();
    this.router.navigateByUrl('towing-company');
  }

  navigateToSubscription() {
    this.menuController.close();
    this.router.navigateByUrl('subscription');
  }

  async signout() {
    this.menuController.close();
    await this.apiService.logout();
   }
}
